import React from 'react'
import { Link, graphql, useStaticQuery} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import './WorkUnitStyles.scss'

const query = graphql`{
  allWorkunitJson(sort: {fields: jsonId, order: ASC}) {
    totalCount
    edges {
      node {
        jsonId
        slug
        title
        subTitle
        featured
        role
        description
        bulletPoints
        alt
        tags {
          class
          definition
          definitionDescription
          name
        }
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              quality: 95
              width: 575
            )
          }
          publicURL
        }
      }
      next {
        jsonId
        slug
      }
      previous {
        jsonId
        slug
      }
    }
  }
}

`


const WorkUnit = () => {
    
    const results = useStaticQuery(query)
    // console.log(results)
    const projects = results.allWorkunitJson.edges
    // console.log("PROJECTS: ", projects)
    const nodes = projects.map(item => item.node)
    // console.log("NODES: ", nodes)
    // const previous = projects.map(item => item.previous)
    // console.log("PREVIOUS: ", previous)
    // const next = projects.map(item => item.next)
    // console.log("NEXT: ", next)
    
    
    const tagRolloverHandler = (event) => {
      // console.log("rollover")
      event.preventDefault()
      event.stopPropagation()
        const defText = event.currentTarget.getAttribute("data-def")
        const defTargetId = event.currentTarget.getAttribute("aria-describedby")
        const defTarget = document.getElementById(defTargetId)
        defTarget.innerHTML = defText
    }

    const tagRollOutHandler = (event) => {
      // console.log("rollout")
      event.preventDefault()
      event.stopPropagation()
      const defTargetId = event.currentTarget.getAttribute("aria-describedby")
      const defTarget = document.getElementById(defTargetId)
      defTarget.innerHTML = ""
    }

    
    function makeHTML(htmlContent) { return {__html: htmlContent}; };

    return <>
    <p className="sr-only" tabIndex="0">The following is a listing of {nodes.length} development projects. The first two are the same featured projects as on the homepage. You may choose to <a href="#uc-berkeley-at-cal">skip those and read the remaining projects</a>.</p>
    { nodes.map((unit, index) => {
    
        const projectThumb = getImage(unit.image) 
        // console.log("projectThumb: ", projectThumb)

        const {tags} = unit

        return (
            <div key={""+unit.jsonId} className="module-work-unit" id={unit.slug} role="group" aria-label={`Project ${index+1} of ${nodes.length}, ${unit.title}`}>
            <div className="featured-backdrop">
               
                <div className="work-unit">
                    <div className="project-header">
                        <div className="project-title">
                        <h2 className="h2"><Link to={`/development/project/${unit.slug}`}>{unit.title}</Link></h2>
                        <p>{unit.subTitle}</p>
                        </div>
                        <div className="work-tags" role="group" aria-label="Technology used in this project">
                            <dfn id={`acc-${unit.jsonId}`} className="definition" aria-live="polite"></dfn>
                            <ul>
                            {tags.map((tag, index)=>{
                                return (
                                    <li key={""+index}>
                                            <abbr
                                                data-def={tag.definition} 
                                                onMouseOver={tagRolloverHandler} 
                                                onMouseOut={tagRollOutHandler}
                                                onFocus={tagRolloverHandler} 
                                                aria-describedby={`acc-${unit.jsonId}`}
                                                tabIndex="0"
                                            >{tag.name}</abbr>
                                    </li>
                                )
                            })}
                               
                            </ul>
                        </div>
                    </div>

                    <div className="project-content">
                        <div className="project-description">
                            <h3 className="h3">{unit.role}</h3>
                            <div dangerouslySetInnerHTML={makeHTML(unit.description)} />
                            <ul>
                                {unit.bulletPoints.map((point, index)=>{
                                    return (
                                        <li key={""+index}>{point}</li>
                                    )
                                })}
                            </ul>

                            <div dangerouslySetInnerHTML={makeHTML(unit.body)} />
                            
                            <Link to={`/development/project/${unit.slug}`} className="see-more">View project</Link>
                        </div>
                        <div className="project-thumb">
                           <div>
                             <Link to={`/development/project/${unit.slug}`} title="View project"> 
                                <GatsbyImage image={projectThumb} alt={unit.alt} className="work-img" />
                               
                            </Link>
                           </div> 
                        </div>

                    </div>
                </div>

            </div>
        </div>

        )

    }) 
 }
 </>;
}


export default WorkUnit