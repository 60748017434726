import React from "react";
import { Link } from 'gatsby';
import WorkUnit from './WorkUnit'

import BarEnd from '../images/svg/barend.inline.svg'
import LtGreenCube from '../images/svg/LT_GREEN_cube.inline.svg'
import OrangeCube from '../images/svg/ORANGE_cube.inline.svg'


const ContentDevelopment = (props) => {

    
    return (
    <section className="section container">
        <div className="content" id="main-content">
            <div className="top-title">
                    <LtGreenCube aria-hidden="true" />
                    <h1 className="h1"><span>Development Work</span></h1>
                </div>
                <WorkUnit />

            <div className="bot-title">
                <OrangeCube aria-hidden="true" />
                <div  className="more-link">
                    <Link to="/blog"><span className="sr-only">View </span>Work<span> prior to circa 2010 in the blog</span> <BarEnd /></Link>  
                </div>
            </div>
        </div>
    </section>
)};

export default ContentDevelopment;
