import React from 'react'
import { useSelector } from 'react-redux'
import ClientOnly from '../ClientOnly'
import Helmet from '../Helmet';
import Header from '../Header';
import Footer from '../Footer';

import ContentDevelopment from '../ContentDevelopment';
import LogoTypeAnimation from '../LogoType'

const DevelopmentLayout = ({ children }) => {
	
	const animation = useSelector(store => store.anim)
    // console.log("animation.played in DEVELOPMENT PAGE: ", animation.played);	
	

	return (
	<>
		<Helmet title="Development" />
		<Header />

		<ClientOnly>{!animation.played && <LogoTypeAnimation />}</ClientOnly>
		
		<main id="development" className="offset-y">
			<div className="container">
				<ContentDevelopment />
			</div>
		</main>
		<Footer />
	</>
)};

export default DevelopmentLayout;
